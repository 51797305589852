import { Body, Button, Col, Grid, H2, Row, Separator } from 'atomic'
import { Query } from 'site/src/data/graphql/graphql-types'
import { graphql } from 'gatsby'
import * as React from 'react'
import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import { ChapterNavigation } from 'site/src/components/mol.chapter-navigation/chapter-navigation.component'
import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import { PageProps } from 'site/src/utils/local-types'
import { ChapterIndex } from './diagnostic-manual-detail'

export interface DiagnosticManualDetailIndexTemplateProps {
  title: string
  description: string
  breadcrumbDictionary?: { [id: string]: string }
  chapterIndex: ChapterIndex[]
  seoDescription: string
  seoImage: string
  seoImageAlt: string
}

class DiagnosticManualDetailIndexTemplate extends React.Component<
  PageProps<DiagnosticManualDetailIndexTemplateProps, Query>
> {
  render() {
    const chapterIndex = this.props.pageContext.chapterIndex
    return (
      <IndexLayout location={this.props.location}>
        <SEO
          socialMedia={{
            canonicalUrl: `${this.props.data.site.siteMetadata.siteUrl}${this.props.location.pathname}${this.props.location.search}`,
            title: this.props.pageContext.title,
            image: this.props.pageContext.seoImage,
            imageAlt: this.props.pageContext.seoImageAlt,
            description: this.props.pageContext.seoDescription
          }}
        />
        <Grid>
          <TitleWithBreadcrumbRow
            title={this.props.pageContext.title}
            addtionalDictionary={this.props.pageContext.breadcrumbDictionary}
          >
            <Body>{this.props.pageContext.description}</Body>
          </TitleWithBreadcrumbRow>
          <Row mb>
            <Col xs={12}>
              <Button
                id='button-start-read-diagnostic'
                kind={'primary'}
                to={chapterIndex[0].url}
              >
                Iniciar leitura
              </Button>
              <Separator />
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row>
            <Col xs={12}>
              <H2>Índice</H2>
              <ChapterNavigation chapterIndex={chapterIndex} currentPath={''} />
            </Col>
          </Row>
          <Separator />
        </Grid>
      </IndexLayout>
    )
  }
}

export default DiagnosticManualDetailIndexTemplate

export const query = graphql`
  query DiagnosticManualDetailIndexTemplate {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
